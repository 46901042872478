import React, { useState, useEffect } from 'react';

const PopUpAd = () => {
    const [OpenAd, setOpen] = useState(false)
    useEffect(() => {
        const shown = localStorage.getItem('popupShown');
        if (!shown) {
          setTimeout(() => {
            setOpen(true);
            localStorage.setItem('popupShown', 'true');
          }, 10000);
        }
      }, []);

    const AdImageURL = 'https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/AdsImages/header_image.webp'
    const onClose = () => {
        setOpen(false);
      };
      return (
        <>
        {OpenAd && (
            <div className="fixed inset-0 flex items-center justify-center px-8 py-8 z-50">
            <div className="relative w-full max-w-xs bg-white rounded-lg shadow-lg overflow-hidden z-50">
              <div
                className="relative h-4 bg-center bg-cover"
              >
                <button
                  className="absolute top-0 right-0 p-4 focus:outline-none"
                  onClick={onClose}
                >
                  <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <img src={AdImageURL} alt="Ad" className="w-full" />
              <div className="px-6 py-4">
                <div className="text-xl font-bold text-gray-900">
                  From Savannah to Beach vacation from $1750
                </div>
                <div className="mt-4">
                  <a
                    href='/resania/safari/bushtobeach'
                    className="px-4 py-2 font-bold text-white bg-customColor rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                  >
                    See the deal
                  </a>
                </div>
              </div>
            </div>
          </div> )}
</>
      );
    };
    
    export default PopUpAd;
    