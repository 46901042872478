import { ChevronRightIcon, } from "@heroicons/react/solid";
import {
  BookOpenIcon,
  GlobeAltIcon,
  SparklesIcon,
  TrendingUpIcon
} from "@heroicons/react/outline";
import HomeCarousel from "../HomeCarouselComponent";

const links = [
  {
    title: "Work Vacation",
    description: "Learn how we intend to intergrate work and vacation",
    icon: GlobeAltIcon,
    link: '/resania/safari/work-vacation'
  },
  {
    title: "Safari Packages",
    description: "Tailor made safari packages for all",
    icon: SparklesIcon,
    link: '/resania/safari'

  },
  {
    title: "Climb Mt Kilimanjaro",
    description: "Explore Mt Kilimanjaro cllimbing routes",
    icon: TrendingUpIcon,
    link: '/resania/kilimanjaro'

  },
  {
    title: "Travel Articles",
    description: "Read our latest news and articles",
    icon: BookOpenIcon,
    link: '/resania/resania-articles'
  }
];

export default function PageNotFoundPage() {
  return (
    <>
      <HomeCarousel
          image_url={[
            "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/thanda.jpg",
            "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/cheetah1.jpg",
            "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/resania_car_traveller.webp",
            "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/Kilimanjaro/climbing-kilimanjaro.webp",
            "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/HotelZanzibar/beach.webp",
            'https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/ndarakwai-carousel.webp'
    ]}
        title1="Page Not Found"
        button_text="Get Started"
        description="Tanzanian National Parks feature the richest variety of wildlife and nature in all of Africa."
        link="/resania/safari"
      />
      <br/>
      <div className=" dark:bg-gray-800">
        <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-xl mx-auto py-16 sm:py-24">
            <div className="text-center">
              <p className="text-sm font-semibold text-yellow-600 uppercase tracking-wide">
                404 error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                This page does not exist.
              </h1>
              <p className="mt-2 text-lg text-gray-500">
                The page you are looking for could not be found or we are
                working on it, please try again later.
              </p>
            </div>
            <div className="mt-12">
              <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">
                Popular pages
              </h2>
              <ul
                className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
              >
                {links.map((link, linkIdx) => (
                  <li
                    key={linkIdx}
                    className="relative py-6 flex items-start space-x-4"
                  >
                    <div className="flex-shrink-0">
                      <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-yellow-50">
                        <link.icon
                          className="h-6 w-6 text-yellow-700"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <h3 className="text-base font-medium text-gray-900">
                        <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
                          <a href={link.link} className="focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            {link.title}
                          </a>
                        </span>
                      </h3>
                      <p className="text-base text-gray-500">
                        {link.description}
                      </p>
                    </div>
                    <div className="flex-shrink-0 self-center">
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-8">
                <a
                  href="/"
                  className="text-base font-medium text-yellow-600 hover:text-yellow-500"
                >
                  Or go back home<span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
