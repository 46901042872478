import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import  { useAuth } from "./Contexts/AuthContext";

const LoginComponent = () => {
  const emailRef = useRef();
  const passRef = useRef();
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { login } = useAuth()
  const history = useHistory()
  const mounted = useRef(false)

  useEffect(() =>{
      mounted.current = true
      return () => {
          mounted.current = false
      }
  }, [])

  function handleSubmit(e) {
    e.preventDefault()
    const loadUserInfo = () => {
        setTimeout(async () => {
                setError('')
                setLoading(true)
                login(emailRef.current.value, passRef.current.value)
                    .then((response) => {
                        history.push('/resania/how-it-works')
                    })
                 .catch((error) => {setError(error.message)})
                 .finally(() => mounted.current && setLoading(false))    
        }, 1000);
      };
    loadUserInfo()
   
  }
  return (


      <div className="w-full dark:bg-gray-700 transition duration-700">
        <div class="w-full h-screen font-sans bg-cover bg-landscape">
          <div class="container flex items-center justify-center flex-1 h-full mx-auto">
            <div class="w-full max-w-lg">
                {error && <alert>{error}</alert>}
              <div class="leading-loose">
                <form class="max-w-sm p-10 m-auto bg-white bg-opacity-25 rounded shadow-xl" onSubmit={handleSubmit}>
                  <p class="mb-8 text-xl font-light text-center">
                    TDC GLOBAL VOTING PORTAL
                  </p>
                  <div class="mb-2">
                    <div class=" relative ">
                      <input
                        type="text"
                        id="login-with-bg-email"
                        class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                        placeholder="email"
                        ref={emailRef}
                      />
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class=" relative ">
                      <input
                        type="password"
                        id="login-with-bg-password"
                        class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                        placeholder="password"
                        ref={passRef}
                      />
                    </div>
                  </div>
                  <div class="flex items-center justify-between mt-4">
                    <button
                      disabled={loading}
                      type="submit"
                      class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                    >
                        {loading ? 'Loading . . ' : 'Login'}
                    
                    </button>
                  </div>
                  <div class="text-center">
                    <a class="right-0 inline-block text-sm font-light align-baseline text-500 hover:text-gray-800" href="/#">
                      Create an account
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default LoginComponent;
