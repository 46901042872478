import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import {Helmet, HelmetProvider} from "react-helmet-async";
import Footer from './Component/Footer'
import SimpleReactLightbox from 'simple-react-lightbox'
import ScrollToTop from './Component/SmoothScroll'
import PageNotFoundPage from './Component/404Page';
import ToggleButton from './Component/Spinner/ToggleButton';
import Loading from './Component/Spinner/spinner';
import ResaniaSurvey from './Containers/ResaniaSurvey';
import LoginComponent from './Component/Firebase/login';
import AuthContextProvider from './Component/Firebase/Contexts/AuthContext';
import ResaniaAcademyComponent from './Containers/ResaniaAcademy';
import Layout from './Component/Layout';


const LandingPage = lazy(() => import('./Containers/LandingPage'))
const SafariLandingPage = lazy(() => import('./Containers/Safari/LandingPage'))
const KilimanjaroLandingPage = lazy(() => import('./Containers/Kilimanjaro/LandingPage'))
const KilimanjaroDetailsPage = lazy(() => import('./Containers/Kilimanjaro/DetailsPage/KilimanjaroDetailspage'))
const SafariDetailsComponemt = lazy(() => import ('./Containers/Safari/DetailPage/SafariDetailsComponemt'))
const HeaderComponent = lazy(()=> import('./Component/headerComponent'))
const BlogLandingPage = lazy(() => import('./Containers/ResaniaBlog/LandingPageComponent'))
const BlogDetailPage = lazy(() => import('./Containers/ResaniaBlog/DetailPage'))
const DayTrpisLandingpage = lazy(() => import ('./Containers/DayTrips/LandingPage'))
const DayTripDetailsComponent = lazy(() => import('./Containers/DayTrips/DetailPage'))
const ZanzibarLandingPage =lazy(() => import('./Containers/Zanzibar/LandingPage'))
const ZanzibarDetailspage = lazy(() => import('./Containers/Zanzibar/DatailsPage'))
const WhentoTravelComponent = lazy(() => import('./Containers/WhenToTravel'))
const AboutUsPage = lazy(() => import('./Containers/AboutPage'))
const ContactUspage = lazy(() => import('./Containers/ContactUsPage'))
const BookingModal = lazy(() => import('./Component/Booking/BookingModalComponent'))
const NationalParksDetailspage  = lazy(() => import('./Containers/NationalParks/DetailsNationalParks'))
const GreatMigrationPage = lazy(() => import('./Containers/GreatMigrationPage'))
const ThandaIslandPage = lazy(() => import('./Containers/ThandaIsland'))
const HotAirballoonPage = lazy(() => import('./Containers/HotAirBallon'))
const HowItWorksComponent = lazy(() => import ('./Containers/HowItWorks'))


const renderLoader = () => <Loading/>;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      <>

      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-yellow-600 sm:text-5xl">500</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page Loading Failed</h1>
                <p className="mt-1 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <a
                  href="/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                >
                  Go back home
                </a>
                <a
                  href="/resania/help-plan"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                >
                  Contact support
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
    }

    return this.props.children;
  }
}
function App() {
  return (
    <HelmetProvider>
      <AuthContextProvider>
        <Router>
           <ScrollToTop>
           <ErrorBoundary>
              <Suspense fallback={renderLoader()}>
        <HeaderComponent/>
          <div className='dark:bg-gray-700 transition duration-700 w-full'>
          <Helmet>
                <title>Resania Travel</title>
                <meta name="description" content="
                Welcome to Resania, we offer a memorable East Africa safari experience that leaves a positive impact on Africa's crucial wilderness areas. Enjoy high quality safari options where you will meet the Serengeti, the Great Migration or one of our many other iconic experiences, our team welcome you with warm hospitality and provide you with an engaging, authentic and extraordinary safari experience that you will never forget
                " />
                 <meta name="keywords" content="Safari, Africa, Kilimanjaro, Tanzania, Mount Kilimanjaro, Zanzibar" />
          </Helmet>

            <Switch>
              <Layout>
              <SimpleReactLightbox>
              <Route path='/' exact component={(props) => <LandingPage />} />
              <Route path='/resania/Spinner' exact component={(props) => <ToggleButton />} />
              <Route path='/resania/kilimanjaro' exact component={(props) => <KilimanjaroLandingPage />} />
              <Route path='/resania/kilimanjaro/:slug' component={(props) => <KilimanjaroDetailsPage/>} />
              <Route path='/resania/safari' exact component={(props) => <SafariLandingPage />} />
              <Route path='/resania/safari/:slug' component={(props) => <SafariDetailsComponemt/>} />
              <Route path='/resania/resania-articles' exact component={(props) => <BlogLandingPage/>} />
              <Route path='/resania/resania-articles/:slug' component={(props) => <BlogDetailPage/>} />
              <Route path='/resania/daytrips' exact component={(props) => <DayTrpisLandingpage/>} />
              <Route path='/resania/daytrips/:slug' component={(props) => <DayTripDetailsComponent/>} />
              <Route path='/resania/zanzibar' exact component={(props) => <ZanzibarLandingPage/>} />
              <Route path='/resania/zanzibar/:slug'  component={(props) => <ZanzibarDetailspage/>} />
              <Route path='/resania/when-to-travel' exact component={(props) => <WhentoTravelComponent/>} />
              <Route path='/resania/about' exact component={(props) => <AboutUsPage/>} />
              <Route path='/resania/contactus' exact component={(props) => <ContactUspage/>} />
              <Route path='/resania/help-plan' exact component={(props) => <BookingModal/>} />
              <Route path='/resania/parks/:slug' component={(props) => <NationalParksDetailspage/>} />
              <Route path='/resania/great-migration' exact component={(props) => <GreatMigrationPage/>} />
              <Route path='/resania/private-island' exact component={(props) => <ThandaIslandPage/>} />
              <Route path='/resania/air-ballon-serengeti' exact component={(props) => <HotAirballoonPage/>} />
              <Route path='/resania/explore-nordic' exact component={(props) => <PageNotFoundPage/>} />
              <Route path='/resania/how-it-works' exact component={(props) => <HowItWorksComponent/>} />
              <Route path='/resania/survey' exact component={(props) => <ResaniaSurvey/>} />
              <Route path='/resania/login' exact component={(props) => <LoginComponent/>} />
              <Route path='/resania/resania-academy' exact component={(props) => <ResaniaAcademyComponent/>} />
              <Route path='/resania/zanzibar/hotel/kendwa' component={() => { window.location.replace("https://sunsetkendwa.com/"); return null; }} />
              <Route path='/resania/zanzibar/hotel/melia' component={() => { window.location.replace("https://www.melia.com/en/hotels/tanzania/zanzibar/melia-zanzibar/index.htm"); return null; }} />
              <Route path='/resania/zanzibar/hotel/baraza' component={() => { window.location.replace("https://www.baraza-zanzibar.com/"); return null; }} />
              <Route path='/resania/zanzibar/hotel/tui' component={() => { window.location.replace("https://www.tui-blue.com/en/en/hotels/tui-blue-bahari-zanzibar/"); return null; }} />
              <Route path='/resania/zanzibar/hotel/zanzi' component={() => { window.location.replace("https://www.zanziresort.com/"); return null; }} />
              <Route path='/resania/zanzibar/hotel/rui' component={() => { window.location.replace("https://www.riu.com/en/hotel/tanzania/zanzibar/hotel-riu-palace-zanzibar/"); return null; }} />
              <Route path='/resania/swedish-site' component={() => { window.location.replace("https://se.resania.com"); return null; }} />
              </SimpleReactLightbox>
              </Layout>
              </Switch>
              <Footer/>
              </div>
              </Suspense>
              </ErrorBoundary>
              
              </ScrollToTop>
              </Router>
          </AuthContextProvider>
          </HelmetProvider>

  );
}

export default App;
