import React, { useState } from 'react';
import LoadSpinner from './spinner';


const ToggleButton = () => {
  const [isLoaded, setIsLoaded] = useState(true);

  const handleIsLoadedToggle = () => {
    setIsLoaded(currentIsLoaded => !currentIsLoaded)
  };

  return (
    <div className="container h-80">
        <br/>
      <button onClick={handleIsLoadedToggle} className='bg-gray-500 h-11 w-10'>
        Toggle LoadSpinner
      </button>

      {isLoaded && <LoadSpinner />}
    </div>
  );
}
export default ToggleButton
