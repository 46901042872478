import React, { useState, useEffect } from 'react';
import PopUpAd from './AdvertismentPopup';
import FloatingButton from './FloatingBookingButton';
import ScrollToTop from './ScrollTotheTop';

const Layout = ({ children }) => {
    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const maxScrollY = document.body.offsetHeight - window.innerHeight;
        const scrollY80 = (window.scrollY / maxScrollY) * 80;
        if (window.scrollY >= scrollY80) {
          setShowScrollButton(true);
        } else {
          setShowScrollButton(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [window.scrollY]);

    return (
        <div>
          {children}
          <PopUpAd/>
          {showScrollButton ? <ScrollToTop /> : <FloatingButton heading="Request Quote" link='/resania/help-plan'/>}
        </div>
      );
    };
    
    export default Layout;
