import React from 'react';
import {  ExternalLinkIcon } from "@heroicons/react/solid";

// const FloatingButton = ({heading, link}) => {

//   return (
//       <button className="fixed bottom-16 right-0 mb-4 mr-4 rounded-full bg-blue-500 text-white py-2 px-4 hover:bg-blue-700 focus:outline-none">
//         Help Plan
//       </button>

//   );
// };

const FloatingButton = ({heading, link}) => {
    return (
        <div className="fixed bottom-16 right-0 mb-4 mr-4 rounded-full text-white py-2 px-4 focus:outline-none">
          <a
            href={link}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-gray-50 bg-customColor hover:bg-gray-50"
          >
            {heading}
            <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </a>
      </div>
    )
}

export default FloatingButton;
