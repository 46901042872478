import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import HomeCarousel from "../../Component/HomeCarouselComponent";

const people = [
  {
    id: 1,
    name: "English",
    avatar: "https://img.icons8.com/color/50/000000/england.png",
  },
  {
    id: 2,
    name: "Swedish",
    avatar: "https://img.icons8.com/color/48/000000/sweden.png",
  },
  {
    id: 3,
    name: "Swahili",
    avatar: "https://img.icons8.com/color/48/000000/tanzania.png",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SurveyEnglish = () => {
  return (
    <div className="w-full dark:bg-gray-700 transition duration-700">
      <div className="container dark:bg-gray-700">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-16 lg:px-8">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScKJofXow-50BGnsBVEUc4F8UAl7u1nH5RX8vZ2mWIkixi0lw/viewform?embedded=true"
            width="100%"
            height="6257"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Resania Survey"
          >
            Loading…
          </iframe>
        </div>
      </div>
    </div>
  );
};

const SurveySwedish = () => {
  return (
    <div className="w-full dark:bg-gray-700 transition duration-700">
      <div className="container dark:bg-gray-700">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-16 lg:px-8">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfw2mvfhsviUcd45esoUfro-0YzeMJLGsKD80tE9xV-rLClHg/viewform?embedded=true"
            width="100%"
            height="6894"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Resania Survey"
            
          >
            Loading…
          </iframe>
        </div>
      </div>
    </div>
  );
};

const SurveySwahili = () => {
  return (
    <div className="w-full dark:bg-gray-700 transition duration-700">
      <div className="container dark:bg-gray-700">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-16 lg:px-8">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfJSBqaRWfkRpqAkeSCZ93k_ER9-z7POC_QaDnVl_-_TyT6qg/viewform?embedded=true"
            width="100%"
            height="4977"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Resania Survey"
          >
            Loading…
          </iframe>
        </div>
      </div>
    </div>
  );
};

const ResaniaSurvey = () => {
  const [selected, setSelected] = useState(people[1]);


  return (
      <>
    <HomeCarousel
        image_url={[
          "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/when-to-travel.webp",
          "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/cheetah1.jpg",
          "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/resania_car_traveller.webp",
          "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/Kilimanjaro/climbing-kilimanjaro.webp",
          "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/HotelZanzibar/beach.webp",
          'https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/ndarakwai-carousel.webp'
  ]}
        title1="Tourism Survey "
        button_text="How we work"
        description="Our clients will have a once-in-a-lifetime vacation. Educate young people on the fundamentals of science and technology."
        link="/resania/how-it-works"
      />
    <div className="w-full dark:bg-gray-700 transition duration-700">
      <div className="container dark:bg-gray-700">
        <div className="mx-auto py-4 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="max-w-xl p-20 items-center">
            <Listbox value={selected} onChange={setSelected}>
              {({ open }) => (
                <>
                  <Listbox.Label className=" block text-xl font-medium text-gray-700 items-center">
                    Select Language ..
                  </Listbox.Label>
                  <div className="mt-1 relative">
                    <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                      <span className="flex items-center">
                        <img
                          src={selected.avatar}
                          alt=""
                          className="flex-shrink-0 h-6 w-6 rounded-full"
                        />
                        <span className="ml-3 block truncate">
                          {selected.name}
                        </span>
                      </span>
                      <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm list-none">
                        {people.map((person) => (
                          <Listbox.Option
                            key={person.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-yellow-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center">
                                  <img
                                    src={person.avatar}
                                    alt=""
                                    className="flex-shrink-0 h-6 w-6 rounded-full"
                                  />
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {person.name}
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-yellow-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          {selected.name === "English" ? (
            <SurveyEnglish />
          ) : selected.name === "Swedish" ? (
            <SurveySwedish />
          ) : (
            <SurveySwahili />
          )}
        </div>
      </div>
    </div>
      </>

  );
};

export default ResaniaSurvey;
