import React, { useEffect } from 'react';

const ScrollToTop = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  
  return (
    <button onClick={handleClick} className="fixed bottom-16 right-0 mb-4 mr-4 rounded-full bg-blue-500 text-white py-2 px-4 hover:bg-blue-700 focus:outline-none">
      <svg
        className="w-6 h-6 text-white fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
      </svg>
    </button>
  );
};

export default ScrollToTop;
