import React from "react";
import HomeCarousel from "../../Component/HomeCarouselComponent";
import { Helmet } from "react-helmet-async";
import { CameraIcon } from "@heroicons/react/solid";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import {
    LightBulbIcon,
    SparklesIcon,
    CodeIcon,
    TagIcon,
  } from "@heroicons/react/outline";
  
  const events = {
    name: 'Tech & Innovation Events',
    href: '#',
    breadcrumbs: [
      { id: 1, name: 'Resania', href: '/' },
      { id: 2, name: 'Academy', href: '#' },
    ],
    images: [
      {
        src: 'https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/dar-2022/IMG_8261.jpg',
        alt: 'Resania Academy',
      },
      {
        src: 'https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/dar-2022/IMG_8255.jpg',
        alt: 'Resania Academy',
      },
      {
        src: 'https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/dar-2022/PHOTO-2022-06-21-09-01-48.jpg',
        alt: 'Resania Academy',
      },
      {
        src: 'https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/dar-2022/IMG_8256.jpg',
        alt: 'Resania Academy',
      },
    ],
    description:
      'Resania Academy, in collaboration with the Dar es Salaam Developer Community, hosted a hands-on workshop focused on development and operation in June 2022. Over 100 young developers attended the session, which included a variety of sessions, networking, special guests, and a shared meal. Many thanks to all of our sponsors.',
    highlights: [
      'Hand-ons Sessions',
      'Netwoking and Experience exchange',
      'DevOps Practicies',
      'Buni Innovation Hub',
    ]
  }
  const event01 = {
    name: 'Resania Program',
    href: '#',
    breadcrumbs: [
      { id: 1, name: 'Resania', href: '/' },
      { id: 2, name: 'Academy', href: '#' },
    ],
    images: [
      {
        src: 'https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/youth-coding/lagos-techie-kwzWjTnDPLk-unsplash.jpg',
        alt: 'Resania Academy',
      },
      {
        src: 'https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/youth-coding/pupilsatthec.jpg',
        alt: 'Resania Academy',
      },
      {
        src: 'https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/youth-coding/20171103.jpg',
        alt: 'Resania Academy',
      },
      {
        src: 'https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/youth-coding/Belenda+making+friends+2.jpg',
        alt: 'Resania Academy',
      },
    ],
    description:
      'Resania Academy, we empower youth to create the world they want to live in using technology by giving them skills and a platform to become effective tech-entrepreneurs with including quality software and hardware programming skills.',
    highlights: [
      '100 + Youth already impacted',
      'Made Partnership with Local Tech Companies and IT proffesionals',
      'Collaboration with The Embassy of Tanzania in Sweden ',
      '5 + Volunteers ',
    ],
  }


const faqs = [
  {
    question: "Interesting , How can I be part of this or help  ?",
    answer:
      "Anyone who shares the same vision is welcome to join this community as a volunteer, traveler, or donor.",
  },
  {
    question: "Where is the Academy and How do you recruit youth ?",
    answer:
      " We currently use the Buni Innovation Hub Center and Primary School computer rooms in Dar es Salaam, but our long-term goal is to reach out to as many schools as possible, leverage their classrooms, and possibly build our own. We recruit young people directly from primary schools as well as through social media.",
  },
  // More questions...
];

const programs = [
    {
      name: "Tech & Innovation Events",
      description:
        "We organize events such as workshops, exhibitions, hackathons, Coding Camps, boot camps and the Girls Entrepreneurship Summit to ignite girls’ interests in Technology",
      icon: LightBulbIcon,
    },
    {
      name: "Coding Clubs",
      description:
        "We conduct weekly coding sessions to  girls in secondary schools across Tanzania to help them develop their own projects and succeed in their careers",
      icon: CodeIcon,
    },
    {
      name: "Mentorship & Incubation",
      description:
        "We provide mentorship and incubation support for emerging tech entrepreneurs to successfully launch and scale-up of their IT-based enterprises",
      icon: TagIcon,
    },
    {
      name: "Resania program",
      description:
        "A Skills Development Program for unemployed female graduates in ICTs aiming at providing them with critical skills needed to reach their full potential in the ICT sector.",
      icon: SparklesIcon,
    },
  ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ResaniaAcademyComponent = () => {

  return (
    <>
      <HomeCarousel
        image_url={[
          "https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/youth-coding/lagos-techie-kwzWjTnDPLk-unsplash.jpg",
          "https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/dar-2022/bestviewing.jpg",
          "https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/dar-2022/IMG_8255.jpg",
          "https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/resania_car_traveller.webp"
        ]}
        title1="Resania Academy"
        button_text="Let's Talk"
        description="Opportunity for youth to learn the basics of Computer Science"
        link="/resania/contactus"
      />
      <Helmet>
        <title>Resania Academy</title>
        <meta
          name="description"
          content="Opportunity for youth to learn the basics of Computer Science."
        />
        <meta
          name="keywords"
          content="Education, Youth Education, Africa Youth , Helping hand, Tanzania"
        />
      </Helmet>

      <div className=" overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block dark:bg-gray-700 bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
          <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
            <div>
              <h2 className="text-base text-yellow-700 font-semibold tracking-wide uppercase">
              Computer | Entrepreneurship
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
             Prepare Youth For The Future
              </h3>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <img
                      alt="resania travel ,safari adventure, climb kilimanjaro and zanzibar vacation , welcome"
                      title="resania travel ,safari adventure, climb kilimanjaro and zanzibar vacation , welcome"
                      className="rounded-lg shadow-lg object-cover object-center"
                      src="https://resania-static-space.ams3.digitaloceanspaces.com/resania-academy/youth-coding/pupilsatthec.jpg"
                      width={1184}
                      height={1176}
                    />
                  </div>
                  <figcaption className="mt-3 flex text-sm text-gray-500">
                    <CameraIcon
                      className="flex-none w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <p className=" mt-6 leading-8 text-lg dark:text-gray-200">
                  <strong className="text-color">The Academy</strong>
                </p>
              </div>
              <div className="mt-5 prose prose-yellow text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p className=" mt-6 leading-8 text-lg dark:text-gray-200">
                  The academy teaches the fundamentals of Information
                  Technology, Computer programming, and Electronics to youth in
                  Tanzania's rural and urban areas.We primarily fund this
                  process through tourism revenues, but we also collect other
                  resources and materials to make it as smooth and efficient as
                  possible.
                </p>
                <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-color sm:text-4xl dark:text-gray-200">
                  Volunteer / Donation
                </h3>
                <p className=" mt-6 leading-8 text-lg dark:text-gray-200 ">
                  To be able to reach out to as many youths as possible, a large
                  amount of resources and funds are required; tourism revenues
                  can only cover a small portion of this; therefore, we
                  gratefully accept any assistance that will directly affect our
                  progress.
                </p>
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <p className="mt-10 mb-10 text-color text-3xl font-bold leading-8 tracking-tight sm:text-4xl">
                Our Programs
                </p>
              </div>
              <div className="mt-10 ">
                <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                  {programs.map((programs) => (
                    <div key={programs.name} className="relative rounded-lg bg-gray-100 py-5 px-5 shadow-xl">
                      <dt>
                        <div className="absolute flex h-12 w-12 items-center justify-center rounded-md  bg-yellow-700 text-white">
                          <programs.icon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </div>
                        <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                          {programs.name}
                        </p>
                      </dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500  dark:bg-gray-700">
                        {programs.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>




    <div className="pt-6">
    <div className="lg:text-center">
                <p className="mt-10 mb-10 text-color text-3xl font-bold leading-8 tracking-tight sm:text-4xl">
                Events 
                </p>
              </div>
        <nav aria-label="Breadcrumb">
          <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            {events.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                    {breadcrumb.name}
                  </a>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a href={events.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {events.name}
              </a>
            </li>
          </ol>
        </nav>

        {/* Image gallery */}
        <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-w-3 aspect-h-4 hidden overflow-hidden rounded-lg lg:block">
            <img
              src={events.images[0].src}
              alt={events.images[0].alt}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg">
              <img
                src={events.images[1].src}
                alt={events.images[1].alt}
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg">
              <img
                src={events.images[2].src}
                alt={events.images[2].alt}
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="aspect-w-4 aspect-h-5 sm:rounded-lg lg:aspect-w-3 lg:aspect-h-4">
            <img
              src={events.images[3].src}
              alt={events.images[3].alt}
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>

        {/* events info */}
        <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{events.name}</h1>
          </div>

          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8">
            {/* Description and details */}
            <div>
              <h3 className="sr-only">Description</h3>

              <div className="space-y-6">
                <p className="text-base text-gray-900">{events.description}</p>
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-900">Highlights</h3>

              <div className="mt-4">
                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                  {events.highlights.map((highlight) => (
                    <li key={highlight} className="text-gray-400">
                      <span className="text-gray-600">{highlight}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <nav aria-label="Breadcrumb">
          <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            {event01.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                    {breadcrumb.name}
                  </a>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a href={event01.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {event01.name}
              </a>
            </li>
          </ol>
        </nav>

        {/* Image gallery */}
        <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-w-3 aspect-h-4 hidden overflow-hidden rounded-lg lg:block">
            <img
              src={event01.images[0].src}
              alt={event01.images[0].alt}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg">
              <img
                src={event01.images[1].src}
                alt={event01.images[1].alt}
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg">
              <img
                src={event01.images[2].src}
                alt={event01.images[2].alt}
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="aspect-w-4 aspect-h-5 sm:overflow-hidden sm:rounded-lg lg:aspect-w-3 lg:aspect-h-4">
            <img
              src={event01.images[3].src}
              alt={event01.images[3].alt}
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>

        {/* events info */}
        <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{event01.name}</h1>
          </div>

          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8">
            {/* Description and details */}
            <div>
              <h3 className="sr-only">Description</h3>

              <div className="space-y-6">
                <p className="text-base text-gray-900">{event01.description}</p>
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-900">Highlights</h3>

              <div className="mt-4">
                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                  {event01.highlights.map((highlight) => (
                    <li key={highlight} className="text-gray-400">
                      <span className="text-gray-600">{highlight}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-5 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently asked questions</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-xl text-gray-900 dark:text-gray-300">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
















        </div>
      </div>
    </>
  );
};
export default ResaniaAcademyComponent;
