import { css } from "@emotion/react";
import GridLoader from "react-spinners/GridLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  color: "#30AF96";
`;

const Loading = () => {

  return (
    <div className="sweet-loading pt-20 mb-10">
      <br/>
      <br/>
      <GridLoader loading={true} css={override} size={30} margin={2} speedMultiplier='1' color='#30AF96'/>
    </div>
  );
}

export default Loading;